export type FleetEligibleForRebate = {
  id: number | string;
  rebateRate: number;
};

export type NativeValue = {
  eligibleFleets: string | undefined;
  date: string;
};

const baseRebateRate = 15;
export const calculateRebate = (
  collisionTotal: number,
  rate = baseRebateRate
) => (collisionTotal <= 0 ? 0 : collisionTotal * (rate / 100));

export const isEligibleForRebate = (
  fleetId: number | undefined | null,
  nativeValue?: NativeValue
): boolean => {
  if (!fleetId) return false;

  const fleetsData = getEligibleFleetsData(nativeValue);
  if (!fleetsData) return false;

  const parsedFleets = parseEligibleFleets(fleetsData);

  return (
    parsedFleets.some(matchesFleetId(fleetId)) ||
    parsedFleets.some(matchesFleetId("*"))
  );
};

export const getFleetRebateDetails = (
  fleetId: number,
  nativeValue?: NativeValue
): FleetEligibleForRebate | undefined => {
  const fleetsData = getEligibleFleetsData(nativeValue);
  if (!fleetsData) return undefined;

  const parsedFleets = parseEligibleFleets(fleetsData);

  if (parsedFleets.some(matchesFleetId(fleetId)))
    return parsedFleets.find(matchesFleetId(fleetId));

  if (parsedFleets.some(matchesFleetId("*")))
    return { id: fleetId, rebateRate: baseRebateRate };
};

export const checkIfRebateIsAvailable = (
  createdAt: string | Date | undefined | null,
  nativeValue?: { date: string }
) => {
  const rebateDate =
    nativeValue?.date ??
    process.env.NEXT_PUBLIC_REBATE_INITIAL_DATE ??
    process.env.REBATE_INITIAL_DATE ??
    "";

  if (!rebateDate || !createdAt) return false;

  return new Date(createdAt) >= new Date(rebateDate);
};

const parseEligibleFleets = (fleets: string): FleetEligibleForRebate[] => {
  try {
    return JSON.parse(fleets);
  } catch (e) {
    console.error("Error parsing eligible fleets for rebate.", e);
    return [];
  }
};

const matchesFleetId =
  (id: number | string) => (fleet: FleetEligibleForRebate) =>
    fleet.id === id;

const getEligibleFleetsData = (nativeValue?: NativeValue): string | undefined =>
  nativeValue?.eligibleFleets ??
  process.env.NEXT_PUBLIC_FLEET_IDS_ELIGIBLE_FOR_REBATE ??
  process.env.FLEET_IDS_ELIGIBLE_FOR_REBATE;
